@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");
body {
  font-family: "Roboto", sans-serif;
  background-color: rgba(208, 201, 194, 0.5);
}

.Titulo {
  color: rgb(0, 150, 215);
  font-size: large;
  font-weight: bolder;
  flex-grow: 1;
}

.Subtitulo {
  color: gray;
  font-weight: bold;
  font-size: small;
  flex-grow: 1;
  width: 100%;
}

.Texto {
  color: gray;
  font-size: small;
  margin: 10px;
  flex-grow: 1;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.botonMenuLateral {
  color: rgb(0, 150, 215);
  font-size: small;
  width: 100%;
  padding: 2px;
  justify-content: start;
  &:hover {
    font-weight: bold;
    background-color: rgba(24, 131, 192, 0.35);
    color: white;
  }
}

.botonBarraSuperior {
  text-align: center;
  color: black;
  font-weight: bold;
}

.botonBarraNav {
  font-weight: bold;
  height: 100%;
  margin-left: 15px;
  &:hover {
    background-color: rgba(24, 131, 192, 0.35);
    color: white;
  }
}

.BotonBody {
  display: flex;
  font-size: x-small;
  background-color: rgb(0, 150, 215);
  color: white;
  border: transparent;
  margin-right: 20px;
  margin-bottom: 5px;
  padding: 5px;
  &:hover {
    color: rgb(0, 150, 215);
    border: 0.125px solid rgb(0, 150, 215);
    background-color: inherit;
    box-shadow: none;
  }
}

.Acordeon {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  background-color: whitesmoke;
  width: 100%;
  height: auto;
}

.AcordeonSummary {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;
  height: auto;
}

.AcordeonDetail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: right;
  text-align: justify;
  width: auto;
  height: auto;
  background-color: rgba(208, 201, 194, 0.25);
  border-top: 0.25px solid rgba(208, 201, 194);
}
.hidden-column {
  display: none !important;
}

.imageContainer {
  position: relative;
  overflow: hidden;
  padding: 2rem;
}

.imageContainer img {
  width: 100px;
  height: 100px;
  transition: transform 0.3s ease;
}

.imageContainer img:hover {
  transform: scale(2);
}
