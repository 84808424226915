.textoPequeñoCal {
  font-size: x-small;
  margin: 0px;
  padding: 0px;
}

.CaratulaCal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: solid;
  padding: 0px;
}

.boxFechaCal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  height: auto;
}
