.CartaNovedad{
    display: flex;
    flex-direction: column;
    background-color:  rgb(246, 240, 240);
    min-width: 250px;
    max-width: 300px;
    height: auto;
    align-items: center;
    justify-content: center;
    border: 0.25px solid  rgba(208, 201, 194);
    border-radius: 10px;
    margin: 10px;
    padding: 0px;
    text-decoration: none;
    &:hover{
        box-shadow:  0px 0px 20px rgba(150, 150, 150 , 0.65);
    }
}

.imagenCN{
    display: flex;
    flex-direction: column;    
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 250px;
}

.CardContentCN {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    height: auto;
    padding: 5px;
    margin: 10px;
    word-wrap: break-word;
}

.CardActionAreaCN {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    &:hover{
        transform: none;
        background-color: inherit;
    }
}