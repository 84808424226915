.BotonNI {
  display: flex;
  font-size: x-small;
  background-color: rgb(0, 150, 215);
  color: white;
  border: transparent;
  margin-right: 20px;
  margin-bottom: 5px;
  padding: 5px;
  &:hover {
    color: rgb(0, 150, 215);
    border: 0.125px solid rgb(0, 150, 215);
    background-color: inherit;
    box-shadow: none;
  }
}

.truncate-textNI {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.textoPequeñoNI {
  font-size: small;
  margin: 0px;
  margin-top: 5px;
}

.textoMuyPequeñoNI {
  font-size: x-small;
  margin: 0px;
  margin-top: 5px;
}
