body {
    background-color: rgba(208, 201, 194, 0.5);
}

.contPrincipalI {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    background-color: rgb(246, 240, 240);
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    border: 0.25px solid rgba(208, 201, 194);
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    margin-top: 15px;
}

.gridPpalI{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 0px;
    padding: 0px;
}

.gridColumnaI{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;    
    margin: 0px;
    padding: 50px;
}

.BordeLateralDer{
    border-right: 0.25px solid rgba(208, 201, 194);
}
.BordeLateralIzq{
    border-left: 0.25px solid rgba(208, 201, 194);
}

.BoxI{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    justify-content: left;
}

