.ModalN {
  display: grid;
  width: 100vw;
  height: 100vh;
  place-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  overflow-y: auto;
}

.inputEditable .MuiFormControl-root {
  border: 1px solid #fff !important;
}

.ContenedorN {
  display: flex;
  flex-direction: column;
  min-width: 90%;
  width: auto;
  max-width: 90%;
  height: auto;
  background-color: rgba(208, 201, 194, 0.75);
  border-radius: 15px;
  margin: 10rem;
  margin-top: 300px;
}

.ContSecundarioN {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  margin: 10px;
  background-color: rgb(246, 240, 240);
  border-radius: 15px;
  border: 0.25px solid rgba(208, 201, 194);
}

.SombraN {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  flex-wrap: wrap;
}

.TituloN {
  width: auto;
  font-size: 5rem;
  font-weight: bold;
  color: rgb(246, 240, 240);
  padding: 1.5rem;
  word-wrap: break-word;
}

.ContenidoN {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  padding: 20px;
  background-color: rgb(246, 240, 240);
  border-radius: 15px;
}

.DividerN {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.GridContenedorN {
  display: flex;
  flex-direction: column;
}

.GridPieN {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
