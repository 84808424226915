.Box{
    display: flex;
    flex-direction: row;
    position: static;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(246, 240, 240);
    align-items: center;
    justify-content: center;
    border-top: 0.25px solid  rgba(208, 201, 194);
}

.gridContainerPdP{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.gridItemPdP {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 20px;
}

.BordesLateralesPdP{
    border-left:0.25px solid  rgba(208, 201, 194);
    border-right:0.25px solid  rgba(208, 201, 194);
}

.TituloPdP{
    text-align: center;
    color: rgb(0, 150, 215);
    font-weight: 600;
    margin-bottom: 20px;
}

.SubtituloPdP{
    color: gray;
    font-size: small;
}

.imgPdP{
    display: block;
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    height: auto;
}
