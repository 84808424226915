.ContenidoPpalCN {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  margin: 10px;
  padding: 20px;
  background-color: rgb(246, 240, 240);
  border: 0.25px solid rgba(208, 201, 194);
  border-radius: 5px;
}

.GridPpalCN {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: self-start;
  justify-content: space-around;
}

.GridItemCN {
  display: flex;
  width: 100%;
  align-items: self-start;
  justify-content: space-between;
  margin: 5px;
}

.DividerCN {
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
  align-self: center;
}

.DividerVerticalCN {
  height: 40px;
  margin: 5px;
}

.TituloPpalCN {
  display: flex;
  width: 100%;
  text-align: center;
  font-size: 30px;
}

.GridBotonesCN {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}

.BotonCN {
  font-size: 15px;
  width: 150px;
  height: 40px;
  margin: 10px;
}

.BoxImagenCN {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  height: 220px;
  border: 0.25px solid rgb(128, 128, 128, 0.65);
  border-radius: 5px;
}
