.carta{
    display: flex;
    flex-direction: column;
    background-color:inherit;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 150px;
    &:hover{
        box-shadow: 0px 0px 15px rgba(150, 150, 150 , 0.5);
        font-weight: bold;
    }
}

.imagen{
    display: flex;
    justify-self: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 150px;
    width: 150px;
    margin:0px;
}
