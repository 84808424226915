.ModalC {
  display: grid;
  width: 100vw;
  height: 100vh;
  place-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  overflow-y: scroll;
}

.ContenedorMC {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: auto;
  background-color: rgba(208, 201, 194, 0.75);
  border-radius: 15px;
}

.ContSecundarioMC {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  margin: 10px;
  padding: 20px;
  background-color: rgb(246, 240, 240);
  border-radius: 15px;
  border: 0.25px solid rgba(208, 201, 194);
}

.DividerMC {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gridBotonesMC {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
