.drawer {
    display: flex;
    flex-direction: column;
    height: 100%; /* Asegura que el drawer tome toda la altura */
  }
  
  .drawerPapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribuye el espacio entre los elementos del menú y el botón de cierre */
    background-color: rgb(246, 240, 240);
    width: 200px;
    box-sizing: border-box;
    border-right: 0.25px solid rgba(208, 201, 194);
    padding: 5px;
  }
  
  .Menu {
    flex-grow: 1; /* Asegura que los elementos del menú tomen todo el espacio disponible */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-top: 60px;
  }
  
  .closeButtonContainer {
    display: flex;
    justify-content: flex-end; /* Alinea el botón al final */
    padding: 10px; /* Añade padding alrededor del botón */
  }
  
  .gridAppBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
  }
  
  .gridCategoria {
    flex-direction: column;
  }
  
  .AppBarTitulo {
    color: rgb(0, 150, 215);
    font-size: large;
    font-weight: bolder;
  }
  
  .AppBarSubtitulo {
    color: gray;
    font-size: small;
  }
  
  .gridItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
  }
  
  .gridImagen {
    height: 40px;
    width: auto;
  }
  
  .botonMenu {
    color: rgb(0, 150, 215);
    font-size: small;
    width: 100%;
    padding: 2px;
    justify-content: start;
  }

  .botonMenu2 {
    color: rgb(0, 150, 215);
    font-size: small;
    width: 100%;
    padding: 2px;
    justify-content: center;
  }

  .botonSesion {
    color: rgb(0, 150, 215);
    font-size: small;
    width: 100%;
    padding: 2px;
    justify-content: center;
  }
  
  .botonMenu:hover {
    font-weight: bold;
    background-color: rgba(24, 131, 192, 0.35);
    color: white;
  }

  .botonMenu2:hover {
    font-weight: bold;
    background-color: rgba(24, 131, 192, 0.35);
    color: white;
  }

  .botonSesion:hover {
    font-weight: bold;
    background-color: rgba(221, 17, 85, 0.35);
    color: white;
  }
  
