body {
    background-color: rgba(208, 201, 194, 0.5);
}

.contPrincipalPXC {
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(246, 240, 240);
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    border: 0.25px solid rgba(208, 201, 194);
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    margin-top: 1%;
    transition: margin-left 0.3s ease-in-out;
}

.menuAbiertoPXC {
    margin-left: 210px;
}

.gridColumnaPXC{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
    margin-top: 5%;
}

.gridColumnaPXC2{
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
    padding: 15px;
    margin-top: 5%;
}

.boxCategoriaPXC{
    display: flex;
    flex-direction: row;
    width: 100%;
    height:auto;
    justify-content: start;
    flex-wrap: wrap;
}

.boxCalendarioPXC {
    display: flex;
    flex-direction: column;    
    width: 100%;
    height: auto;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.gridNovedadesPXC{
    display: flex;
    flex-direction: row;    
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.TituloPXC {
    color: rgb(0, 150, 215);
    font-size: large;
    font-weight: bolder;
}

.SubtituloPXC {
    color: gray;
    font-weight: bold;
    font-size: small;
    width: 100%;
    margin-left: 10px;    
}

.TextoPXC{
    color: gray;
    font-size: small;
    margin: 10px;
}

.boxCompetenciaPXC{
    margin-top: 5px;
    padding-top: 10px;
    width: 100%;
    height: auto;
    border-top: 0.25px solid rgba(208, 201, 194); 
}

