.general {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  background-color: rgba(208, 201, 194, 0.5);
}

.contenido {
  display: flex;
  background-color: rgb(246, 240, 240);
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: fit-content;
  height: auto;
  margin: 15px;
  padding: 10px;
  border-radius: 5px;
  border: 0.25px solid rgba(208, 201, 194);
}

.pieDePagina {
  display: flex;
  background-color: rgb(246, 240, 240);
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  margin: 7.5px;
  padding: 5px;
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
}

.tituloContenido {
  font-weight: bolder;
  color: black;
  text-align: center;
  margin-top: 20px;
}

.subtituloContenido {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
  text-align: center;
}
