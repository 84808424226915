.BoxIS {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.BotonIS {
  margin: 5px;
  width: auto;
  align-self: flex-end;
}

.FormularioIS {
  width: 100%;
}
