.AppBar{
    background-color: rgb(246, 240, 240);
    position: fixed;
    box-shadow: 0px 0px 30px rgba(150, 150, 150 , 0.5); 
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: auto;    
}

.Grid {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    margin: 0px;
}

.gridBotones{
    background-color: inherit;
    display:flex;
    align-items: center;
    justify-content: right;
    color: black;
    height: auto;
}

.botonBN{
    font-weight: bold;
    height: 100%;
    margin-left: 15px;
    &:hover{
        background-color:rgba(24, 131, 192, 0.35) ;
        color: white;
    }
}

.favl-logo{
    display: flex;
    justify-content: center;    
    align-items: center;    
}

.botonNav{
    text-align: center;
    color: black;
    font-weight: bold;
}